<script setup lang="ts">
import axios from 'axios';

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const {
    customRouterState,
    navigateToPage
} = useCustomRouter()
const { updateGuessBTC } = authStore

const { addNewSuccessToast } = useToast();
const BTCPrice = ref('')
const oldBTCPrice = ref('')
const statusCompare = ref('')
const userGuess = ref('')
const capUserPrize = ref('')
const priceDiff = ref(0)
const userResult = ref('')
const isAddShellPopup = ref(false)
const isRunOutShells = ref(false)
const usedShell = ref(authState.value.shellCount === 0 ? 0 : 1);
const message = ref('')
const { encryptMessage, randomUUID } = useEncryption();
const getCurrentPrice = async () => {
    try {
        const response = await axios.get(`https://data-api.binance.vision/api/v3/avgPrice?symbol=BTCUSDT`);
        // const response = await axios.get(`https://api.binance.com/api/v3/avgPrice?symbol=BTCUSDT`);
        oldBTCPrice.value = BTCPrice.value
        BTCPrice.value = response?.data.price;
        return response.data.price;
    } catch (error) {
        console.error('Error fetching average price:', error);
        throw error;
    }
}

const comparePrice = async () => {
    await getCurrentPrice()
    priceDiff.value = parseFloat(BTCPrice.value) - parseFloat(capUserPrize.value);

    if (priceDiff.value < 0) {
        statusCompare.value = 'down';
    } else if (priceDiff.value > 0) {
        statusCompare.value = 'up';
    }
    await setResult()
    showResult.value = true
}

const setResult = () => {
    if (userGuess.value === statusCompare.value) {
        userResult.value = 'win'
        //call endpoint to add point
        sendNewGuessBTC()
    } else {
        userResult.value = 'lose'
        //call endpoint to deduct shells
        sendNewGuessBTC()
    }
}

const handleClick = async (guess: string) => {
    //show countdown 10 secs
    capUserPrize.value = BTCPrice.value
    userGuess.value = guess
    if (authState.value.shellCount <= 0) {
        isRunOutShells.value = true
        //addNewSuccessToast('You have dont have any shells', 'Success');
        return
    } else {
        showCountdown.value = true
        if (!countdownInterval) {
            startCountdown()
        }
    }
}

const countdown = ref(10); // 10;
const showCountdown = ref(false);
const showResult = ref(false);
let countdownInterval: NodeJS.Timeout | null = null;
let intervalId: NodeJS.Timeout | null = null;
let audio = new Audio('/sound/Countdown_Timer.WAV');
let winSound = new Audio('/sound/guess_correct.wav');
let wrongSound = new Audio('/sound/guess_fail.wav');

const startCountdown = () => {
    
    if (countdownInterval) {
        clearInterval(countdownInterval);
        audio.pause();
    }
    countdownInterval = setInterval(async () => {
        audio.play();
        countdown.value -= 1;
        if (countdown.value === 0) {
            clearInterval(countdownInterval as NodeJS.Timeout);
            countdownInterval = null;
            showCountdown.value = false;
            await comparePrice()
            countdown.value = 10;
            if (userResult.value === 'win') {
                setTimeout(() => {
                    winSound.play();
                }, 0);
            }
        }
    }, 1000); // 1000ms = 1s
}

const continuePlay = () => {
    showResult.value = false
}

const exitResult = () => {
    showResult.value = false
}

watch(showResult, (newValue) => {
    if (newValue) {
        setTimeout(() => {
            showResult.value = false
        }, 5000)
    }
})

// const sendGuessBTC = async () => {
//     const response = await $fetch(
//         `${authStore.apiUrl}/api/penny-whale/webapp/campaign/guess-btc-price`,
//         {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//                 "X-API-KEY": authStore.apiKey,
//             },
//             body: {
//                 dataToValidate: authState.value.authString,
//                 timestamp: Math.floor(Date.now()),
//                 status: userResult.value,
//                 bidPrice: parseFloat(capUserPrize.value),
//                 currentPrice: parseFloat(BTCPrice.value),
//                 betShellQuantity: usedShell.value
//             },
//             ignoreResponseError: true,
//         }
//     );
//     if (response?.statusCode == 400) {
//         addNewSuccessToast(response?.message, "success")
//     } else {
//         updateGuessBTC(response?.guessBtcPoint, response?.shellCount)
//     }
// };

const sendNewGuessBTC = async () => {

    const messageDetails = JSON.stringify({
        gameId: randomUUID(),
        previousBitcoinPrice: capUserPrize.value,
        previousBitcoinTimestamp: Date.now() - 60000,
        bidShellCount: usedShell.value,
        guess: userGuess.value
    });
    const encryptedMessage = await encryptMessage(messageDetails);
    console.log(encryptedMessage);
    const response = await $fetch(
        `${authStore.apiUrl}/api/penny-whale/webapp/campaign/guess-btc-price-v2`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": authStore.apiKey,
            },
            body: {
                dataToValidate: authState.value.authString,
                encryptBody: encryptedMessage,
            },
            ignoreResponseError: true,
        }
    );
    if (response?.statusCode == 400) {
        addNewSuccessToast(response?.message, "success")
    } else {
        updateGuessBTC(response?.guessBtcPoint, response?.shellCount)
    }
};

const setUsedShells = (noShells: number, type: string) => {
    message.value = ''
    if (type === 'max') {
        usedShell.value = authState.value.shellCount
    }
    if (type === 'min') {
        usedShell.value = authState.value.shellCount > 0 ? 1 : 0
    }

    if (type === 'add') {
        if (usedShell.value + noShells > authState.value.shellCount) {
            message.value = 'You have dont have enough shells';
            return;
        }
        usedShell.value += noShells;
    }
    if (type === 'sub') {
        if (authState.value.shellCount === 0) {
            usedShell.value = 0;
        } else {
            usedShell.value = usedShell.value - noShells
            if (usedShell.value < 1) {
                usedShell.value = 1;
            }
        }
    }
}

const closeAddShellPopup = () => {
    message.value = ''
    isAddShellPopup.value = false
}

const handleRunOutShells = () => {
    isRunOutShells.value = false
    navigateToPage('shellpackage')
}

onMounted(() => {
    getCurrentPrice()
    intervalId = setInterval(async () => {
        await getCurrentPrice()
    }, 30000)

    // onUnmounted(() => clearInterval(intervalId as NodeJS.Timeout));
})

onUnmounted(() => {
        clearInterval(intervalId as NodeJS.Timeout); ;
    
})


</script>

<template>
        <div class="bg-black text-white">
            <div class="bg-[url('/images/guessbtc/Background.png')] bg-cover bg-center">
                <div class="flex flex-col h-screen overflow-y-scroll pb-20">
                    <div class="flex flex-row items-center justify-center pt-10">
                        <img src="public/images/guessbtc/Masthead.png" alt="" class=" px-6 pt-6">
                    </div>
                    <div v-if="!showResult" class="flex flex-row mt-3 items-center justify-center gap-1 pt-5 pb-1">
                        <!-- <img src="/images/dollar-coin.png" alt="" class="w-6 h-6"> -->
                        <p class="font-bold text-2xl">{{authState.guessBitcoinPoint}} Points</p>
                    </div>
                    <div v-if="!showResult" class="flex flex-col justify-center">
                        <div class=" relative text-center text-black font-bold">
                            <img src="/images/guessbtc/Price_Box.png" alt="" class="w-1/2 mx-auto">
                            <div
                                class=" absolute top-5 right-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-3 text-xl your-selector">
                                <p class="text-lg text-[#000080] pt-3">Current Price</p>
                            <p class="animate-pulse text-2xl text-[#00008B]">${{ BTCPrice.replace(/(\.\d{4})\d+/, '$1')
                                    }}</p>
                            </div>
                        </div>
                        <div v-if="!showCountdown" class="text-center pt-4">
                            <p class="text-base text-[#000080]">Guess the bitcoin price in next 10 seconds</p>
                        </div>
                        <div v-if="showCountdown" class="text-center pt-4 ">
                            <div class="rounded-full ring-4 ring-white inline-block w-10 h-10 ">
                                <p class="text-white font-bold text-2xl pt-0.5">{{ countdown }}</p>
                            </div>
                        </div>
                        <!-- <div @click="isAddShellPopup = true"
                        class="text-center pt-4 flex flex-row items-center justify-center gap-1 px-3 pb-2 mx-auto">
                        <img src="public/images/multitap.png" alt="" class="w-7 h-7">
                        <p class="text-3xl font-bold text-yellow-300 mb-1">x{{ usedShell }}</p>
                    </div> -->
                        <div @click="isAddShellPopup = true" class="flex flex-row justify-center pt-2">
                            <div class="relative">
                                <img src="public/images/guessbtc/Shell_Box.png" alt="" class="w-24 h-16">
                            <img src="/public/images/multitap.png" alt="" class="absolute top-1/2 left-1/4 -translate-x-1/2 -translate-y-1/2 w-9 h-9 ">
                            <span class="absolute bottom-4 left-10 text-xl text-[#800000] font-bold pl-1">x {{usedShell }}</span>
                            </div>
                        </div>
                        <div class="flex flex-row justify-center gap-4 w-2/3 mx-auto pt-4">
                        <img @click="handleClick('up')" src="/images/guessbtc/Up_Button.png" alt="" class="w-20 h-20">
                            <img @click="handleClick('down')" src="/images/guessbtc/Down_Button.png" alt=""
                                class="w-20 h-20">
                        </div>
                        <div class="flex flex-row items-center justify-center mt-2">
                            <button @click="navigateToPage('guessleaderboard')"
                                class="bg-[#89CFF0] text-white font-bold py-2 px-4 rounded-2xl">Leaderboard</button>
                        <img @click="navigateToPage('shellpackage')" src="/public/images/guessbtc/Buy_Shells.png" alt=""
                            class="w-1/3 mt-1">
                        </div>
                    </div>

                    <!-- Result Section -->
                    <div v-if="showResult" class="fixed inset-0 bg-black opacity-50" />
                    <div v-if="showResult" class="text-center bg-opacity-70 h-full w-screen z-40">
                        <div>
                            <div>
                                <img v-if="userResult === 'win'" src="public/images/guessbtc/Win_Banner_New.png" alt=""
                                    class="w-3/4 mx-auto -mt-14">
                                <img v-if="userResult === 'lose'" src="/public/images/guessbtc/Lose_Banner.png" alt=""
                                    class="w-1/2 mx-auto pb-10">
                            </div>
                            <div v-if="userResult === 'win'"
                                class=" -mt-12 w-full mx-auto absolute top-3/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <p class=" text-base font-bold text-yellow-300 mb-1 w-1/2 mx-auto py-1 px-1 rounded-lg">You
                                    earned {{ usedShell*10 }} point</p>
                            </div>
                            <div class=" relative text-center text-black font-bold -mt-2">
                                <img src="/images/guessbtc/Price_Box.png" alt="" class="w-2/5 mx-auto">
                                <div
                                    class=" absolute top-5 right-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-1 text-xl your-selector">
                                    <p class="text-xs text-[#000080] pt-3">Current Price</p>
                                <p class=" text-base text-[#00008B]">${{ capUserPrize.replace(/(\.\d{4})\d+/, '$1') }}
                                    </p>
                                </div>
                            </div>
                            <div class=" relative text-center text-black font-bold -mt-3">
                                <img src="/images/guessbtc/Price_Box.png" alt="" class="w-1/2 mx-auto">
                                <div
                                    class=" absolute top-5 right-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-3 text-xl your-selector">
                                    <p class="text-lg text-[#000080] pt-3">Current Price</p>
                                    <div class="flex flex-row items-center justify-center">
                                        <p class="animate-pulse text-2xl text-[#00008B]">${{
                                            BTCPrice.replace(/(\.\d{4})\d+/,'$1') }}</p>
                                        <!-- <img :src="'public/images/guessbtc/' + (statusCompare === 'moon' ? 'Ticker_arrow_up.png' : 'Ticker_arrow_down.png')" alt="" class="w-4 ml-2 pb-3"> -->
                                        <img v-if="statusCompare === 'down'"
                                            src="/public/images/guessbtc/Ticker_arrow_down.png" alt=""
                                            class="w-4 ml-2 pb-3">
                                        <img v-if="statusCompare === 'up'"
                                        src="/public/images/guessbtc/Ticker_arrow_up.png" alt="" class="w-4 ml-2 pb-3">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row justify-center pt-6">
                            <div class="relative">
                                <img src="public/images/guessbtc/Shell_Box.png" alt="" class="w-auto h-12 pt-1">
                                <img src="public/images/multitap.png" alt="" class="w-auto h-7 absolute top-2 left-2">
                                <span class="absolute bottom-1 right-2 text-base text-[#800000] font-bold">{{
                                    authState.shellCount }}</span>
                            </div>
                            <img src="public/images/guessbtc/Continue.png" alt="" @click="continuePlay()"
                                class="w-auto h-14">
                            <img src="public/images/guessbtc/Exit.png" alt="" @click="exitResult()" class="w-14 h-14">
                        </div>

                    </div>
                </div>

                <!-- popup section -->
                <div v-if="isAddShellPopup" class="fixed inset-0 bg-black opacity-70" />
                <div v-if="isAddShellPopup" class="fixed inset-0 flex items-center justify-center h-auto mx-10 ">
                    <div
                        class=" bg-[#89CFF0] relative flex flex-col items-center justify-center px-4 py-2 text-base border-2 border-white rounded-lg">
                        <div class="flex items-center justify-center">
                            <p class="mb-5 text-center text-white">
                                Add shells to guess the price
                            </p>
                        </div>
                        <div class="flex flex-col items-center justify-start gap-2 overflow-y-auto ">
                            <div class="flex items-center justify-center gap-1">
                                <p class="text-left text-white">
                                    You have {{ authState.shellCount }}
                                </p>
                                <img src="public/images/multitap.png" alt="" class="w-5 h-5">
                            </div>
                            <div class="flex flex-row gap-1 items-center justify-center mt-2">
                                <button @click="setUsedShells(0, 'min')"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0 px-1 rounded">Min</button>
                                <button @click="setUsedShells(5,'sub')"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0 px-1 rounded">-5</button>
                                <button @click="setUsedShells(1,'sub')"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0 px-1 rounded">-1</button>
                                <div class="px-4 py-1 bg-white rounded">
                                    <p class="text-gray-500 text-center">
                                        {{ usedShell }}
                                    </p>
                                </div>
                                <button @click="setUsedShells(1,'add')"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0 px-1 rounded">+1</button>
                                <button @click="setUsedShells(5,'add')"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0 px-1 rounded">+5</button>
                                <button @click="setUsedShells(authState.shellCount, 'max')"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-0 px-1 rounded">Max</button>
                            </div>
                        </div>
                        <p class="text-center text-white">{{ message }}</p>
                        <div class="flex items-center justify-center my-2 gap-2">
                            <!-- <button class="px-4 py-2 bg-red-500 border-2 border-white rounded-lg">
                            <p @click="closeAddShellPopup()" class="text-center text-white">
                                Close
                            </p>
                        </button> -->
                            <button class="px-4 py-2 bg-green-500 border-2 border-white rounded-lg my-3">
                                <p @click="closeAddShellPopup()" class="text-center text-white">
                                    Add
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Run out energy -->
                <div v-if="isRunOutShells" class="fixed inset-0 bg-black opacity-70" />
                <div v-if="isRunOutShells" class="fixed inset-0 flex items-center justify-center h-auto mx-10 ">
                    <div
                        class=" bg-slate-100 relative flex flex-col items-center justify-center px-4 py-2 text-base border-2 border-white rounded-lg">
                        <div class="flex items-center justify-center">
                            <p class="mb-5 text-center text-[#000080] text-xl uppercase your-selector">
                                Oh no! You run out of shells
                            </p>
                        </div>
                        <div class="flex flex-col items-center justify-start gap-2 overflow-y-auto ">
                            <div class="flex flex-col items-center justify-center gap-1">
                                <div class="relative">
                                    <img src="public/images/multitap.png" alt="" class="w-16 h-16">
                                    <p class="text-center absolute -bottom-6 left-1/2 transform -translate-x-1/2 text-4xl text-red-500 font-extrabold">
                                    x{{ authState.shellCount }}
                                    </p>
                                </div>
                                <p class="text-center text-[#000080] pt-6 uppercase text-base your-selector">Get more shells to continue guessing</p>
                                <div class="flex flex-row items-center justify-center mt-2">
                                    <img @click="handleRunOutShells()" src="/public/images/guessbtc/Buy_Shells.png" alt=""
                                    class="w-2/3 h-16">
                                    <img src="public/images/guessbtc/Exit.png" alt="" @click="isRunOutShells = false" class="w-16 h-16">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
</template>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/avengeance-mightiest-avenger');
@import url('https://fonts.cdnfonts.com/css/cardenio-modern');

.your-selector {
    font-family: 'Avengeance Mightiest Avenger', cursive;
}

.cardenios {
    font-family: 'Cardenio Mordern Regular', cursive;
}

.shellstroke {
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFF00;
    text-shadow: 2px 2px 5px rgba(128, 0, 0, 0.5);
}
</style>
