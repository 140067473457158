<script setup lang="ts">
const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const {
    customRouterState,
    navigateToPage
} = useCustomRouter()

</script>
<template>
    <div class="bg-black text-white">
        <div class="bg-[url('/images/guessbtc/Background.png')] bg-cover bg-center">
            <div class="h-screen pb-20">
                <img src="/public/images/guessbtc/MainMasthead.png" alt="" class="px-6">
                <div class="relative">
                    <!-- <img src="/public/images/guessbtc/Price_Box.png" alt="" class="w-2/3 mx-auto ring-8 rounded-2xl ring-[#800000] ring-inset pt-1"> -->
                    <div class="bg-gradient-to-r from-indigo-500 to-cyan-500 mx-auto w-2/3 rounded-2xl">
                        <img src="/public/images/guessbtc/Price_Box.png" alt="" class=" mx-auto">
                    </div>
                    <div class=" text-[#000080] absolute top-2 left-1/2 transform -translate-x-1/2">
                        <p class="">YOU HAVE EARNED</p>
                        <p class="text-3xl font-bold text-center">{{ authState.guessBitcoinPoint.toLocaleString() }}</p>
                    </div>
                </div>
                <img @click="navigateToPage('guessbitcoin')" src="/public/images/guessbtc/Guess_Button.png" alt=""
                    class="w-1/3 mx-auto mt-4">
                <div class="flex flex-row justify-center pt-2">
                    <div class="relative">
                        <img src="public/images/guessbtc/Shell_Box.png" alt="" class="w-auto h-24 pt-1">
                        <img src="public/images/multitap.png" alt="" class="w-auto h-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <span class="absolute bottom-1 right-3 text-base text-[#800000] font-bold">{{ authState.shellCount }}</span>
                    </div>
                </div>
                <div class="flex flex-row items-center justify-center gap-4 pt-4">
                    <img src="/public/images/guessbtc/Earn_Shells.png" @click="navigateToPage('earn')" alt="" class="w-1/3">
                    <img src="/public/images/guessbtc/Buy_Shells.png" @click="navigateToPage('shellpackage')" alt="" class="w-1/3">
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.cdnfonts.com/css/cardenio-modern');

  .cardenios {
    font-family: 'Cardenio Mordern Regular', cursive;
  }

  .shellstroke {
      font-weight: bold;
      text-transform: uppercase;
      color: #FFFF00;
      text-shadow: 2px 2px 5px rgba(128, 0, 0, 0.5);
      /* transform: rotate(-10deg); */
  }
</style>