<script setup lang="ts">
import useToast from '@/composables/useToast'
import { useWebAppHapticFeedback } from 'vue-tg'

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const { updatePoint, refreshSignIn, resetRewardClaimed,updateAccumulatedEnergy } = authStore
const { toastState, addNewInfoToast } = useToast();

const levelNames = [
  "Bronze",    // From 0 to 4999 coins
  "Silver",    // From 5000 coins to 24,999 coins
  "Gold",      // From 25,000 coins to 99,999 coins
  "Platinum",  // From 100,000 coins to 999,999 coins
  "Diamond",   // From 1,000,000 coins to 2,000,000 coins
  "Epic",      // From 2,000,000 coins to 10,000,000 coins
  "Legendary", // From 10,000,000 coins to 50,000,000 coins
  "Master",    // From 50,000,000 coins to 100,000,000 coins
  "GrandMaster", // From 100,000,000 coins to 1,000,000,000 coins
  "Lord"       // From 1,000,000,000 coins to ∞
];

const levelMinPoints = [
  0,        // Bronze
  5000,     // Silver
  25000,    // Gold
  100000,   // Platinum
  1000000,  // Diamond 
  2000000,  // Epic
  10000000, // Legendary
  50000000, // Master
  100000000,// GrandMaster
  1000000000// Lord
];

const levelIndex = ref(0);
const pendingUpdatePoints = ref(0);
const progressBarWidth = ref(0);
const clicks = ref<{ id: number, x: number, y: number }[]>([]);
const pointsToAdd = ref(1);
const isHit = ref(false);
const energyGenerated = ref(0);
const clickableRect = ref(null);
const showEnergyEmpty = ref(false);
const showCampaignLeaderboard = ref(true);
const campaignEnded = ref(true);

const clearOldClicks = async () => {
  isHit.value = false;
  clicks.value = clicks.value.filter(click => Date.now() - click.id < 1000);

  const nextLevelMin = levelMinPoints[levelIndex.value + 1];
  if (authState.value.totalPoint >= nextLevelMin && levelIndex.value < levelNames.length - 1) {
    levelIndex.value += 1;
  }

  if (pendingUpdatePoints.value > 0) {
    const result = await updatePoint(pendingUpdatePoints.value);
    calculateEnergyGenerated();
    pendingUpdatePoints.value = 0;
  }
};

const {
  customRouterState,
  navigateToPage
} = useCustomRouter()

onMounted(() => {
  for (let i = 0; i < levelMinPoints.length; i++) {
    if (authState.value.totalPoint >= levelMinPoints[i]) {
      levelIndex.value = i;
      pointsToAdd.value = i + authState.value.tapPower;
    } else {
      break;
    }
  }
  updatePoint(1); // Always add 1 point so that if user have full energy it is show as full the next second it refilled
  calculateProgress();

  const clearOldClicksInterval = setInterval(clearOldClicks, 5000);
  onUnmounted(() => clearInterval(clearOldClicksInterval));

  const calculateEnergyGeneratedInterval = setInterval(calculateEnergyGenerated, 1000);
  onUnmounted(() => clearInterval(calculateEnergyGeneratedInterval));
});

watch(customRouterState, (newValue, oldValue) => {
  if (newValue === 'treasure') {
    refreshSignIn();
  }
});

const handleCardClick = (e: MouseEvent) => {
  isHit.value = true;
  if (clickableRect.value === null) return;
  const rect = clickableRect.value.getBoundingClientRect()
  const x = e.clientX - rect.left - rect.width / 2;
  const y = e.clientY - rect.top - rect.height / 2;
  clickableRect.value.style.transform = `perspective(1000px) rotateX(${-y / 10}deg) rotateY(${x / 10}deg)`;
  useWebAppHapticFeedback().impactOccurred('light');
  setTimeout(() => {
    clickableRect.value.style.transform = '';
  }, 100);

  setTimeout(() => {
    isHit.value = false;
  }, 1000);


  if (authState.value.energy + energyGenerated.value - pointsToAdd.value - pendingUpdatePoints.value > 0) {
    pendingUpdatePoints.value += pointsToAdd.value;
    const changedTouches = e.changedTouches;
    const clickedX = changedTouches ? changedTouches[0].pageX : 0;
    const clickedY = changedTouches ? changedTouches[0].pageY : 0;
    if (clickedX < rect.left || clickedX > rect.right || clickedY < rect.top || clickedY > rect.bottom) {
      clicks.value.push({ id: Date.now(), x: ((rect.right - rect.left) / 2) + rect.left, y: ((rect.bottom - rect.top) / 2) + rect.top });
    } else {
      clicks.value.push({ id: Date.now(), x: clickedX, y: clickedY });
    }
  } else if (toastState.value.currentToasts.length === 0) {
    showEnergyEmpty.value = true
    // addNewInfoToast('You ran out of energy. Please recharge by boosting or wait before tapping', 'info')
  }

};

const calculateProgress = () => {
  if (levelIndex.value >= levelNames.length - 1) {
    progressBarWidth.value = 100
    return;
  }
  const currentLevelMin = levelMinPoints[levelIndex.value];
  const nextLevelMin = levelMinPoints[levelIndex.value + 1];
  const progress = ((authState.value.totalPoint - currentLevelMin) / (nextLevelMin - currentLevelMin)) * 100;
  progressBarWidth.value = Math.min(progress, 100);
};

const calculateEnergyGenerated = () => {
  energyGenerated.value = Math.max(Math.floor(((Date.now() - new Date(authState.value.lastTapAt).getTime() - 1000) * authState.value.maximumEnergy) / 3600000), 0); // Added 1000 miliseconds for the cost of network connection
  updateAccumulatedEnergy(authState.value.energy + energyGenerated.value - pendingUpdatePoints.value);
  calculateProgress();
}

const closePopover = () => {
  showEnergyEmpty.value = false
  navigateToPage('booster')
}

const closeCampaign = () => {
  showCampaignLeaderboard.value = false
  campaignEnded.value = false
  resetRewardClaimed()
  navigateToPage('leaderboard')
}

const closeCampaignbanner = () => {
  showCampaignLeaderboard.value = false
}

</script>

<template>
  <div class="overflow-hidden h-full overscroll-none">
    <div class="bg-blue-950 flex justify-center overscroll-none">
      <div class="w-full h-screen text-white font-bold flex flex-col max-w-xl overscroll-none">
        <div class="px-4 z-10 flex flex-row overscroll-none">
          <div class="w-1/2">
            <div class="flex items-center space-x-2 pt-4">
              <div class="p-1 rounded-lg bg-[#189FDA]">
                <img src="/images/main-character.png" class="text-[#d4d4d4] h-6 w-6" />
              </div>
              <div>
                <p class="text-sm">{{ authState.username || authState.firstName || "user" +
                  authState.tgId.toString().slice(-4) }}</p>
              </div>
            </div>
          </div>
          <div class="flex items-center pt-4 pr-4 w-1/2">
            <div class="w-full">
              <div class="flex justify-between">
                <p class="text-sm">{{ levelNames[levelIndex] }}</p>
                <p class="text-sm">{{ levelIndex + 1 }} <span class="text-[#95908a]">/ {{ levelNames.length }}</span>
                </p>
              </div>
              <div class="flex items-center mt-1 border-2 border-[#43433b] rounded-full">
                <div class="w-full h-2 bg-[#43433b]/[0.6] rounded-full">
                  <div class="progress-gradient h-2 rounded-full" :style="{ width: `${progressBarWidth}%` }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow mt-4 bg-[#f0fffe] rounded-t-[48px] relative top-glow z-0 overscroll-none">
          <div
            class="absolute top-[2px] left-0 right-0 bottom-0 bg-[url('/images/bg.png')] bg-cover bg-center rounded-t-[46px]">
            <div class="px-4 flex justify-center">
              <div class="px-4 py-2 flex items-center space-x-2">
                <img src="/images/dollar-coin.png" alt="Dollar Coin" class="w-8 h-8" />
                <!-- <p class="text-4xl text-white">{{ (points + pendingUpdatePoints).toLocaleString() }}</p> -->
                <p class="text-3xl text-white">{{ (authState.point + pendingUpdatePoints).toLocaleString() }}</p>
              </div>
            </div>
            <div class="px-4 py-1 flex justify-center">
              <div class="w-full h-auto p-2 rounded-full circle-outer" ref="clickableRect"
                v-touch:tap="handleCardClick">
                <div class="w-full h-full rounded-full circle-inner">
                  <img :src="isHit ? '/images/main-character-hit.png' : '/images/main-character.png'"
                    alt="Main Character" class="w-full h-full" />
                </div>
              </div>
            </div>
            <div class="px-4 flex flex-row justify-between">
              <div class="flex flex-row items-center">
                <img src="/images/energy.png" alt="Treasure" class="w-8 h-8">
                <p>{{
                  (authState.energy + energyGenerated - pendingUpdatePoints) > 0 ?
                  (authState.energy + energyGenerated - pendingUpdatePoints) < authState.maximumEnergy ?
                    (authState.energy + energyGenerated - pendingUpdatePoints).toLocaleString() :
                    authState.maximumEnergy.toLocaleString() : 0 }} / {{ (authState.maximumEnergy).toLocaleString()
                    }}</p>
              </div>
              <div class="flex flex-row items-center" @click="navigateToPage('booster')">
                <img src="/images/booster.png" alt="" class="w-10 h-10" />
                <p>Boost</p>
                <span v-if="authState.boosterEnergy > 0"
                  class="absolute top right-0.5 grid min-h-[10px] min-w-[10px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 text-xs text-white mr-2">
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showEnergyEmpty" popover id="confirmation"
          class="bg-black bg-opacity-50 h-screen w-screen flex items-center justify-center ">
          <div class="bg-blue-900 border rounded-2xl w-11/12 max-h-fit max-w-xl text-white ">
            <div class="flex flex-col items-center justify-center gap-2">
              <img src="/images/low_energy.png" alt="" class="w-32 h-32 mt-4 rounded-3xl" />
              <div class="m-2">
                <p class="text-center text-sm">You ran out of energy.</p>
                <p class="text-center text-sm">Please recharge by boosting</p>
              </div>
              <div class="flex flex-row justify-center gap-4 items-center text-white mb-4">
                <button class="bg-yellow-500 text-gray-700 py-2 px-3 rounded font-bold" @click="closePopover()">Go to
                  Boost</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="campaignEnded" popover id="confirmation"
          class="bg-black bg-opacity-50 h-screen w-full flex items-center justify-center z-20"
          @click="campaignEnded = false">
          <div class="bg-blue-900 border rounded-2xl w-11/12 max-h-fit max-w-md mx-4 text-white ">
            <div class="flex flex-col items-center justify-center gap-2">
              <img src="/images/penny-whale-icon.png" alt="" class="w-auto h-32 mt-4 rounded-3xl" />

              <p class="text-wrap text-center text-xl mx-4">Season #1 Tournament has Ended</p>

              <div class="flex flex-row justify-center gap-4 items-center text-white my-4">
                <button v-if="!authState.bannerclaimS1Rewards && authState.isJoinCampaign"
                  class="bg-yellow-500 text-gray-700 py-2 px-3 rounded font-bold" @click="closeCampaign()">Claim
                  Rewards!</button>
                <button v-else class="bg-yellow-500 text-gray-700 py-2 px-3 rounded font-bold"
                  @click="closeCampaign()">See Result</button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div v-if="authState.secretCampaignJoin" popover id="confirmation"
          class="bg-black bg-opacity-50 h-screen w-full flex items-center justify-center ">
          <div class="bg-blue-900 border rounded-2xl w-11/12 max-h-fit max-w-md mx-4 text-white ">
            <div class="flex flex-col items-center justify-center gap-2">
              <img src="/images/penny-whale-icon.png" alt="" class="w-auto h-32 mt-4 rounded-3xl" />

              <p class="text-wrap text-center text-xl mx-4">You have joined the campaign and earned 204,900 points.
                Don't miss out $1,000 USDT this week!</p>

              <div class="flex flex-row justify-center gap-4 items-center text-white my-4">
                <button class="bg-yellow-500 text-gray-700 py-2 px-3 rounded font-bold"
                  @click="closeCampaign()">LEADERBOARD</button>
              </div>
            </div>
          </div>
        </div>-->
        <div v-else-if="showCampaignLeaderboard" popover id="confirmation"
          class="bg-black bg-opacity-50 h-screen w-full flex items-center justify-center " @click="closeCampaignbanner()">
          <div class="bg-blue-900 rounded-2xl w-11/12 max-h-fit max-w-md mx-4 text-white" @click="closeCampaign()">
            <img src="/images/season2-campaign-banner.jpeg" class="w-full h-auto rounded-2xl" >
          </div>
        </div> 
      </div>

      <div v-for="click in clicks" :key="click.id" class="absolute text-4xl font-bold text-white pointer-events-none"
        :style="{
          top: `${click.y - 42}px`,
          left: `${click.x - 28}px`,
        }" v-motion="{
          initial: {
            y: 0,
            opacity: 1
          },
          enter: {
            y: -50,
            opacity: 0
          }
        }">
        +{{ pointsToAdd }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.progress-gradient {
  background: linear-gradient(to right, #90ef89, #d692dd, #726edd);
}

.top-glow {
  box-shadow: 0 -26px 20px rgba(165, 192, 183, 0.3);
}

/* Define the keyframes for the blinking animation */
@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Apply the animation to the dot class */
.dot {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  animation: blink 1s infinite;
}

.circle-outer {
  background: linear-gradient(to bottom, #98ACB2, #0e3039);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 90vw;
  max-width: 360px;
  max-height: 360px;
}

.circle-inner {
  background: radial-gradient(circle, #1D3D4D, #193f51);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
</style>
